<template>
  <img src="../assets/1.png" alt="" style="width:100%">
  <img src="../assets/2.png" alt="" style="width:100%">
  <div class="min-h-screen bg-gray-900 flex items-center justify-center">
  
    <div class="text-center p-6 max-w-xl w-full animate-fadeIn">
      <h1 class="text-5xl font-bold text-white mb-6 transition-transform duration-500 ease-in-out transform hover:scale-105">WealthRank</h1>
      <p class="text-xl text-gray-300 mb-8">We are launching soon! Stay tuned for updates.</p>

      <!-- Email Subscription Form -->
      <form class="flex flex-col sm:flex-row justify-center items-center mb-8">
        <input
          v-model="email"
          type="email"
          placeholder="Enter your email"
          class="px-4 py-2 rounded-md text-black focus:outline-none focus:ring-2 focus:ring-purple-500 w-full sm:w-auto mb-4 sm:mb-0 transition duration-300 ease-in-out"
        />
        <button
          @click.prevent="subscribe"
          class="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-md sm:ml-4 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Notify Me
        </button>
      </form>

      <p v-if="successMessage" class="text-green-500 mb-8">{{ successMessage }}</p>

      <!-- Office Address & Contact Details -->
      <div class="bg-gray-800 p-6 rounded-lg shadow-md text-left transition-transform duration-500 ease-in-out transform hover:scale-105">
        <h2 class="text-2xl text-purple-500 font-semibold mb-2">Our Office</h2>
        <p class="text-gray-300 mb-4">Wealthrank.ai</p>
        <p class="text-gray-300 mb-2">14 Wall Street, 20th Floor, New York City</p>
        <p class="text-gray-300 mb-4">NewYork 10005, USA</p>

        <h3 class="text-xl text-purple-500 font-semibold mb-2">Contact Us</h3>
        <p class="text-gray-300 mb-2">Phone: +1 (646) 466-7748</p>
        <p class="text-gray-300">Email: pankaj.mamde@wealthrank.ai</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      successMessage: ""
    };
  },
  methods: {
    subscribe() {
      if (this.validateEmail(this.email)) {
        this.successMessage = "You have been subscribed!";
      } else {
        this.successMessage = "Please enter a valid email address.";
      }
    },
    validateEmail(email) {
      const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return re.test(email);
    }
  }
};
</script>

<style scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-fadeIn {
  animation: fadeIn 1s ease-in-out;
}
</style>
